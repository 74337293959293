import React, { FC, useState } from 'react';

import { Button, Modal } from 'antd';
import { CheckCircleTwoTone, CopyOutlined } from 'components/icons';
import { copyToClipboard } from 'utils/functions';

interface IPublicApiCredentialSecretModal {
  clientId: string;
  secret: string;
  visible: boolean;
  onOk: () => void;
}

const PublicApiCredentialSecretModal: FC<IPublicApiCredentialSecretModal> = ({ clientId, secret, visible, onOk }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const onCopy = () => {
    copyToClipboard(`Client ID: ${clientId}\nSecret: ${secret}`);
    setCopied(true);
  };

  const onModalClose = (isOnOk: boolean) => {
    setCopied(false); // Reset state, so it doesn't still say "copied" on subsequent credential creations.

    if (isOnOk) {
      onOk();
    }
  };

  return (
    <Modal
      wrapClassName="Modal"
      visible={visible}
      title="Credentials"
      cancelButtonProps={{ hidden: true }}
      okText="Got it"
      onOk={() => onModalClose(true)}
      onCancel={() => onModalClose(false)}
      centered
      width="720px"
      forceRender
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '16px' }}>
        <h4>Client ID: {clientId}</h4>
        <h4>Secret: {secret}</h4>
        <Button icon={copied ? <CheckCircleTwoTone /> : <CopyOutlined />} title="Copy to clipboard" onClick={onCopy}>
          {copied ? 'Copied' : 'Copy'}
        </Button>
        <div>Warning: The secret is not stored anywhere and will no longer be available after this is closed!</div>
      </div>
    </Modal>
  );
};

export default PublicApiCredentialSecretModal;
