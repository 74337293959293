export * from './routes';
export * from './orderTemplates';

export enum FeatureFlags {
  PatientPropertyConfig = 'feature_frontend_patient_property_configuration',
}

// Note: sequential order of keys in this object matters:
// it's used in ordering by status
export const DEVICE_STATUS = {
  createdForOrder: 'Created for order',
  inStock: 'In stock',
  readyToShip: 'Ready to ship',
  awaitingPickup: 'Awaiting pickup',
  inTransitToPatient: 'In transit to patient',
  atPatient: 'At patient',
  inTransitToLab: 'In transit to lab',
  atLab: 'At lab',
  resultsReady: 'Results ready',
  returnToSender: 'Return to sender',
  deleted: 'Deleted',
  replaced: 'Replaced',
  atLabPastSLA: 'At lab past SLA',
  rejectedByLab: 'Rejected by lab',
  error: 'Error',
  unknown: 'Unknown',
};

export const TUBE_TYPE = {
  serum: 'Serum',
  edta: 'EDTA',
  lh: 'LH',
};

export const PATIENT_EXPERIENCE_STATUS = {
  none: 'Disabled',
  notActivated: 'Not activated',
  // Both statuses below are considered activated from a customer service perspective
  activated: 'Activated',
  collectionComplete: 'Activated - collection complete',
};

export const LAB_ORDER_STATUS = {
  proposed: 'Proposed',
  draft: 'Draft',
  requested: 'Requested',
  received: 'Received',
  accepted: 'Accepted',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  completed: 'Completed',
};
export const KIT_ITEM_TYPES = {
  printable: 'printable',
  packingInstructions: 'packingInstructions',
  shippingLabel: 'shippingLabel',
  deviceKit: 'deviceKit',
  box: 'box',
  bioTouch: 'bioTouch',
} as const;

export const KIT_ITEM_TYPES_LABELS: Record<keyof typeof KIT_ITEM_TYPES, string> = {
  printable: 'Printable',
  packingInstructions: 'Packing Instructions',
  shippingLabel: 'Shipping Label',
  deviceKit: 'Device Kit',
  box: 'Box',
  bioTouch: 'BioTouch',
} as const;

export const RESULT_DELIVERY = {
  na: {
    name: 'N/A',
    value: 'na',
    placeholder: '',
  },
  fax: {
    name: 'Fax',
    value: 'fax',
    placeholder: 'Enter Fax number',
  },
  mailedReport: {
    name: 'Mailed Report',
    value: 'mailedReport',
    placeholder: 'Enter mailing address',
  },
  secureTransmission: {
    name: 'Secure Transmission',
    value: 'secureTransmission',
    placeholder: 'Enter routing',
  },
};

export const PROJECT_STATUSES = {
  open: 'open',
  archived: 'archived',
};

export const PROJECT_TYPES = {
  onDemandSampling: 'onDemandSampling',
  onDemandTesting: 'onDemandTesting',
} as const;

export const PROJECT_TYPE_LABELS: Record<keyof typeof PROJECT_TYPES, string> = {
  onDemandSampling: 'On Demand Sampling',
  onDemandTesting: 'On Demand Testing',
};

export const PROJECT_PURPOSES = {
  research: 'Research',
  diagnosticTesting: 'Diagnostic Testing',
};

export const PROJECT_PHYSICIAN_APPROVAL_MODES = {
  customerProvided: 'Customer Provided',
};

// key - user role that exists in the API
// value - label to use in the UI
export const ALL_USER_ROLES = {
  internalAdmin: 'Internal Admin',
  internalCustomerService: 'Internal Customer Service',
  internalShipper: 'Internal Shipper',
  customerAdmin: 'Customer Admin',
  customerOrganizer: 'Customer Organizer',
  customerSponsor: 'Customer Sponsor',
} as const;

// same keys as ALL_USER_ROLES, values are the same as keys
export const USER_ROLES = Object.keys(ALL_USER_ROLES).reduce(
  (acc, val) => ({ ...acc, [val]: val }),
  {} as Record<keyof typeof ALL_USER_ROLES, keyof typeof ALL_USER_ROLES>
);

export const LABELING_STRATEGIES = {
  tassoCareV1: 'tassoCareV1',
  code128: 'code128',
  largeDatamatrix: 'largeDatamatrix',
  blank: 'blank',
  none: 'none',
};

export const LABELING_STRATEGIES_LABELS: Record<keyof typeof LABELING_STRATEGIES, string> = {
  tassoCareV1: 'Tasso Care V1',
  code128: 'Code 128',
  largeDatamatrix: 'Large Datamatrix',
  blank: 'Blank',
  none: 'None',
};

export const GENDERS = {
  cisMale: 'cisMale',
  cisFemale: 'cisFemale',
  transgenderMale: 'transgenderMale',
  transgenderFemale: 'transgenderFemale',
  nonBinary: 'nonBinary',
  none: 'none',
  unspecified: 'unspecified',
  other: 'other',
  unknown: 'unknown',
  declinedToAnswer: 'declinedToAnswer',
};

export const ASSIGNED_SEXES = {
  male: 'male',
  female: 'female',
  unknown: 'unknown',
};

// TD-2549: copied from utils/constants in customer portal.
// Unknown if they're still "correct", can only be confident they are consistent per TD-2549's requirements.
export const RACES = {
  american_indian_alaska_native: 'American Indian or Alaska Native',
  asian: 'Asian',
  black_african_american: 'Black or African American',
  native_hawaiian_other_pacific_islander: 'Native Hawaiian or Other Pacific Islander',
  hispanic_latino: 'Hispanic or Latino',
  white: 'White',
  other: 'Other',
  declined_to_answer: 'Declined To Answer',
};

// deprecated by TD-1164 but values may still exist in database, so support is needed
export const LEGACY_DEVICE_REPLACEMENT_REASONS = {
  product_performance: 'Product Performance',
  sample_taken_at_patient_too_long: 'Sample Taken, Kept at Patient too long',
  sample_in_transit_too_long: 'Sample in transit too long',
  no_fire: 'No Fire',
  did_not_adhere_to_arm: 'Did Not Adhere to Arm',
  replacement_of_return_packaging: 'Replacement of Return Packaging/Label',
  insufficient_volume_for_analysis_at_lab: 'Insufficient Volume for analysis at lab',
  user_error: 'User Error',
  lost_in_mail: 'Lost in Mail',
  no_blood_collected: 'No Blood Collected',
};

export const DEVICE_REPLACEMENT_REASONS = {
  fulfillment_error: [
    { label: 'Received Wrong Item', value: 'received_wrong_item' },
    { label: 'Received Device Damaged', value: 'received_device_damaged' },
    { label: 'Lost In The Mail', value: 'lost_in_the_mail' },
    { label: 'Missing Kit Component', value: 'missing_kit_component' },
  ],
  product_issue: [
    { label: 'Insufficient Volume Collected', value: 'insufficient_volume_collected' },
    { label: 'No Blood Collected', value: 'no_blood_collected' },
    { label: 'No Fire (Button was depressed, no apparent lancing (no mark))', value: 'no_fire' },
    { label: 'Poor Adhesion (Fell off arm)', value: 'poor_adhesion' },
    { label: 'Unknown Defect', value: 'unknown_defect' },
    { label: 'Upstream Clotting', value: 'upstream_clotting' },
    {
      label: 'User Error - Could Not or Difficult To Detach Tube From Device',
      value: 'user_error_could_not_or_difficult_to_detach_tube_from_device',
    },
    { label: 'User Error - Damage', value: 'user_error_damage' },
    { label: 'User Error - Did Not Depress Button', value: 'user_error_did_not_depress_button' },
    { label: 'User Error - Did Not Read Instructions For Use', value: 'user_error_did_not_read_ifu' },
    { label: 'User Error - Did Not Watch Video', value: 'user_error_did_not_watch_video' },
    { label: 'User Error - Difficulty Pressing Button', value: 'user_error_difficulty_pressing_button' },
    {
      label: 'User Error - General Difficulty Using The Device',
      value: 'user_error_general_difficulty_using_the_device',
    },
    { label: 'User Error - Difficulty Capping the Tube', value: 'user_error_difficulty_capping_the_tube' },
    { label: 'User Error - Difficultly Activating Heat pack', value: 'user_error_difficultly_activating_heat_pack' },
    { label: 'User Error - Shipping, etc.', value: 'user_error_shipping' },
  ],
  unknown: 'Unknown',
};

export const PACKAGE1_TYPES = {
  none: 'none',
  firstClass: 'firstClass',
  priority: 'priority',
  priorityExpress: 'priorityExpress',
  upsGround: 'upsGround',
  ups1DayAir: 'ups1DayAir',
  ups1DayAirSaver: 'ups1DayAirSaver',
  ups2DayAir: 'UPS 2-Day Air',
  ups3DaySelect: 'ups2DayAir',
  fedEx2Day: 'fedEx2Day',
  fedExOneRate2Day: 'fedExOneRate2Day',
  fedExHome: 'fedExHome',
  fedExOvernight: 'fedExOvernight',
} as const;

export type Package1Type = keyof typeof PACKAGE1_TYPES;

// Tasso to Patient
export const PACKAGE1_TYPE_LABELS: Record<Package1Type, string> = {
  none: 'None',
  firstClass: 'USPS First Class',
  priority: 'USPS Priority',
  priorityExpress: 'USPS Priority Express',
  upsGround: 'UPS Ground',
  ups1DayAir: 'UPS Next Day Air',
  ups1DayAirSaver: 'UPS Next Day Air Saver',
  ups2DayAir: 'UPS 2-Day Air',
  ups3DaySelect: 'UPS 3-Day Select',
  fedEx2Day: 'FedEx 2-Day',
  fedExOneRate2Day: 'FedEx One Rate 2-Day',
  fedExHome: 'FedEx Home',
  fedExOvernight: 'FedEx Overnight',
} as const;

export const BIOTOUCH_UNSUPPORTED_PACKAGE1_KEYS: string[] = [PACKAGE1_TYPES.fedExOneRate2Day];

// Patient to Lab
export const PACKAGE2_TYPES = {
  none: 'none',
  firstClass: 'firstClass',
  priority: 'priority',
  priorityExpress: 'priorityExpress',
  upsGround: 'upsGround',
  ups1DayAir: 'ups1DayAir',
  ups1DayAirSaver: 'ups1DayAirSaver',
  ups2DayAir: 'ups2DayAir',
  ups3DaySelect: 'ups3DaySelect',
  fedEx2Day: 'fedEx2Day',
  fedExOvernight: 'fedExOvernight',
} as const;

export type Package2Type = keyof typeof PACKAGE2_TYPES;

export const PACKAGE2_TYPE_LABELS: Record<Package2Type, string> = {
  none: 'None',
  firstClass: 'USPS First Class',
  priority: 'USPS Priority',
  priorityExpress: 'USPS Priority Express',
  upsGround: 'UPS Ground',
  ups1DayAir: 'UPS Next Day Air',
  ups1DayAirSaver: 'UPS Next Day Air Saver',
  ups2DayAir: 'UPS 2-Day Air',
  ups3DaySelect: 'UPS 3-Day Select',
  fedEx2Day: 'FedEx 2-Day',
  fedExOvernight: 'FedEx Overnight',
} as const;

export const DISTRIBUTION_MODEL_SOURCES = {
  directToPatient: 'directToPatient',
  atCustomerSite: 'atCustomerSite',
} as const;

export const DISTRIBUTION_MODELS = {
  directToPatient: 'Direct to patient',
  atCustomerSite: 'At customer site',
} as const;

export const LAB_ADAPTER_KEYS = {
  ARUP: 'ARUP Laboratories',
  coremedica: 'CoreMedica',
  CRL: 'Clinical Reference Laboratory',
  molecularUS: 'Molecular Testing Labs',
};

export const LABEL_IDENTIFIER_SOURCES = {
  hrid: 'hrid',
  barcode: 'barcode',
  identifierPool: 'identifierPool',
  barcodePrelabeled: 'barcodePrelabeled',
} as const;

export const LABEL_IDENTIFIER_SOURCE_LABELS: Record<keyof typeof LABEL_IDENTIFIER_SOURCES, string> = {
  hrid: 'Tasso Identifier (hrid)',
  barcode: 'Customer Identifier (barcode)',
  identifierPool: 'Identifier Pool',
  barcodePrelabeled: 'Pre-labeled Barcode',
} as const;

export const NO_ORDER_PLEASE = 'none';

export const MANIFEST = {
  origin: '98119',
  shipperType: 'usps',
};

/**
 * An order is considered "pending" until it reaches at least
 * the "readyToShip" status.
 * Status listed below all happen *before* readyToShip is set.
 * If the current status is "logisticsComplete" or beyond that,
 * it means that a device was already created.
 * The regular flow of status is:
 * 1) accepted (order was just created)
 * 2) readyForLogistics (pre device creation step)
 * 3) inLogistics (device is being created)
 * 4) logisticsComplete (device was created with status "createdForOrder")
 * 5) readyToShip (device is fully ready)
 *
 * These are also all statuses in which an order can be cancelled. Think twice before
 * modifying this list. See [the Core API Order Cancellation design doc](https://tassoinc.atlassian.net/wiki/spaces/CODE/pages/1902477334/Order+Cancellation+-+Core+API)
 * and the enum [NoCancelStatuses](https://gitlab.com/tassoinc/core-api/tasso-core-api/-/blob/main/service/src/api/schema/core/constants.ts?ref_type=heads#L395-405)
 * for additional context.
 */
export const PendingOrderStatuses = new Set([
  'temporaryFailure',
  'accepted',
  'readyForLogistics',
  'inLogistics',
  'logisticsComplete',
]);

// Admins are the only users who can cancel orders and, as mentioned above, only pending orders can be cancelled.
// Normally all pending orders are excluded from any user's view (`ShipmentRecipientList/index.tsx`). To enable amdins
// to select pending orders for cancellation, we have to include them in their view of the world. To that end, this set
// excludes `logisticsComplete` from `PendingOrderStatuses` above. This status is used because it is the status the
// order sits in during the wait window that was added to support cancellation (EXP-438 and EXP-533).
export const AdminPendingOrderStatuses = new Set(['temporaryFailure', 'accepted', 'readyForLogistics', 'inLogistics']);
