/* eslint-disable no-template-curly-in-string */
import React, { FC, useEffect, useRef } from 'react';

import { Form, Input, Modal, FormInstance, InputNumber, Popconfirm, Table, Button, Radio } from 'antd';

import { CustomAttributeDefinitionProject } from 'context/projects';
import { DeleteOutlined } from 'components/icons';
import { ColumnsType } from 'antd/lib/table';
import Notification from 'utils/notification';
import { ICustomAttributeDefinition } from 'context/customAttributeDefinitions';

type Props = {
  visible: boolean;
  useOrderTemplates: boolean;
  customAttributeDefinition: CustomAttributeDefinitionProject | ICustomAttributeDefinition | null;
  onClose: () => void;
  createCustomAttributeFcn: (customAttributeDefinition: CustomAttributeDefinitionProject) => void;
  editCustomAttributeFcn: (customAttributeDefinition: CustomAttributeDefinitionProject, id?: string) => void;
};

const CustomAttributeModal: FC<Props> = ({
  visible,
  useOrderTemplates,
  customAttributeDefinition,
  onClose,
  createCustomAttributeFcn,
  editCustomAttributeFcn,
}) => {
  const form = useRef<FormInstance>(null);
  const [allowedValues, setAllowedValues] = React.useState([] as string[]);
  const [singleAllowedValue, setSingleAllowedValue] = React.useState('');
  const [inputType, setInputType] = React.useState('text');
  const [isRequired, setIsRequired] = React.useState(false);
  const allowedNameCharacters = /^[A-Za-z0-9 \-_()]*$/i;

  const resetState = (): void => {
    setAllowedValues([]);
    setSingleAllowedValue('');
    setInputType('text');
    setIsRequired(false);
  };

  const handleClose = (): void => {
    form.current!.resetFields();
    onClose();
  };

  const handleSubmit = (): void => {
    form
      .current!.validateFields()
      .then((values) => {
        const attribute: CustomAttributeDefinitionProject = {
          name: values.name,
          ...(!useOrderTemplates
            ? { displayName: values.displayName?.length > 0 ? values.displayName : values.name }
            : {}),
          ...(typeof values.maxLength !== 'undefined' && values.maxLength ? { maxLength: values.maxLength } : {}),
          ...(isRequired ? { required: true } : {}),
          ...(allowedValues.length > 0 ? { allowedValues } : {}),
        };
        form.current!.resetFields();
        if (customAttributeDefinition) {
          editCustomAttributeFcn(
            attribute,
            'id' in customAttributeDefinition ? customAttributeDefinition.id : undefined
          );
        } else {
          createCustomAttributeFcn(attribute);
        }
        onClose();
        resetState();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validateMessages = {
    required: '${label} is required!',
  };

  function inputTypeOnChange(event: any) {
    setInputType(event.target.value);
    if (event.target.value === 'text') {
      setAllowedValues([]);
    }
  }

  function isRequiredOnChange(event: any) {
    setIsRequired(event.target.value);
  }

  function handleSingleAllowedValueChange(event: any) {
    setSingleAllowedValue(event.target.value);
  }

  function handleSingleAllowedValueAdd() {
    if (singleAllowedValue.length === 0) {
      return;
    }

    if (allowedValues.includes(singleAllowedValue)) {
      Notification({
        type: 'error',
        message: 'Duplicate attribute value not allowed',
        description: '',
      });
      setSingleAllowedValue('');
      return;
    }

    setAllowedValues(allowedValues.concat([singleAllowedValue]));
    setSingleAllowedValue('');
  }

  useEffect(() => {
    resetState();

    if (visible) {
      form.current!.setFieldsValue({
        id: customAttributeDefinition && 'id' in customAttributeDefinition ? customAttributeDefinition.id : '',
        name: customAttributeDefinition?.name || '',
        displayName:
          customAttributeDefinition && 'displayName' in customAttributeDefinition
            ? customAttributeDefinition.displayName
            : '',
        maxLength: customAttributeDefinition?.maxLength || '',
        inputType: 'text',
      });

      setIsRequired(customAttributeDefinition?.required || false);
      setAllowedValues(customAttributeDefinition?.allowedValues || []);

      if ((customAttributeDefinition?.allowedValues?.length ?? 0) > 0) {
        setInputType('dropdown');
      }
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      wrapClassName="Modal CustomAttributeModal"
      title={customAttributeDefinition ? 'Edit attribute' : 'Create attribute'}
      okText={customAttributeDefinition ? 'Update' : 'Create'}
      cancelText="Cancel"
      onCancel={handleClose}
      onOk={handleSubmit}
      centered
      width={360}
    >
      <Form
        ref={form}
        className="Form CustomAttributeModal__Form"
        layout="vertical"
        validateMessages={validateMessages}
        requiredMark="optional"
      >
        <Form.Item
          name="name"
          label="Attribute Name"
          required
          rules={[
            { type: 'string', required: true },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (!allowedNameCharacters.test(value)) {
                  return Promise.reject(
                    'Name may only contain letters, numbers, spaces, hyphens, underscores, and parentheses.'
                  );
                }
                if (value.length > 0 && !value.trim()) {
                  return Promise.reject('Name must not be all spaces.');
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            placeholder="Enter name"
            type="string"
            disabled={!useOrderTemplates && customAttributeDefinition !== null}
          />
        </Form.Item>
        {!useOrderTemplates && (
          <Form.Item
            name="displayName"
            label="Display name"
            rules={[
              () => ({
                validator(_, value) {
                  if (!allowedNameCharacters.test(value)) {
                    return Promise.reject(
                      'Name may only contain letters, numbers, spaces, hyphens, underscores, and parentheses.'
                    );
                  }
                  if (value.length > 0 && !value.trim()) {
                    return Promise.reject('Name must not be all spaces.');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Enter display name for order entry" />
          </Form.Item>
        )}
        <div>
          Attribute type
          <br />
          <Radio.Group
            options={[
              { label: 'Text', value: 'text' },
              { label: 'Drop-down', value: 'dropdown' },
            ]}
            name="inputType"
            value={inputType}
            onChange={inputTypeOnChange}
          />
          <br /> <br />
        </div>
        <Form.Item name="maxLength" label="Maximum length for text field" hidden={inputType === 'dropdown'}>
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Maximum length of 1000"
            min="0"
            max="1000"
            disabled={inputType === 'dropdown'}
          />
        </Form.Item>

        <div hidden={inputType === 'text'}>
          {allowedValues.length === 0 ? (
            <div>
              <Form.Item name="singleAllowedValue" label="Drop-down list values">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Input
                          type="text"
                          value={singleAllowedValue}
                          onChange={handleSingleAllowedValueChange}
                          disabled={inputType === 'text'}
                        />
                      </td>
                      <td>
                        <Button onClick={handleSingleAllowedValueAdd} disabled={inputType === 'text'}>
                          Add
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form.Item>
            </div>
          ) : null}
          {allowedValues.length > 0 ? (
            <Table
              size="small"
              pagination={false}
              dataSource={allowedValues as any[]}
              columns={
                [
                  { title: 'Drop-down list values' },
                  {
                    title: '',
                    render: (item) => (
                      <li key={item}>
                        <div>
                          <Popconfirm
                            title="Remove choice?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                              const newValues = allowedValues.filter((record) => record !== item);
                              setAllowedValues(newValues);
                            }}
                          >
                            <DeleteOutlined className="DeleteAction" />
                          </Popconfirm>
                        </div>
                      </li>
                    ),
                  },
                ] as ColumnsType<string>
              }
              footer={() => (
                <table>
                  <tr>
                    <td>
                      <Input
                        type="text"
                        value={singleAllowedValue}
                        onChange={handleSingleAllowedValueChange}
                        disabled={inputType === 'text'}
                      />
                    </td>
                    <td>
                      <Button onClick={handleSingleAllowedValueAdd} disabled={inputType === 'text'}>
                        Add
                      </Button>
                    </td>
                  </tr>
                </table>
              )}
            />
          ) : (
            ''
          )}
        </div>
        <Form.Item label="Requirement in orders" requiredMark={false}>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            defaultValue={false}
            options={[
              { label: 'Required', value: true },
              { label: 'Optional', value: false },
            ]}
            name="required"
            value={isRequired}
            onChange={isRequiredOnChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomAttributeModal;
